import React, { useEffect, useState } from "react";
import Bus from './bus'
import Execute from "./Execute.jsx"
import Info from "./Info.jsx"
import Flash from "./flash.jsx"
import Logo from './images/nav-logo.png'
import CatImg from './images/PlasCAT_logo.png'
import Instructions from "./Instructions.jsx"
import { S3Client } from "@aws-sdk/client-s3"
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";


const App = () => {

  const [s3Client, setS3Client] = useState(null)


  useEffect(()=>{
    // the configuration information is fetched from the .env file
    const config = {
        client_region: process.env.REACT_APP_S3_CLIENT_REGION,
        id_region: process.env.REACT_APP_AWS_ID_REGION,
        aws_id: process.env.REACT_APP_AWS_ID,
    }

    const client = new S3Client({
      region: config.client_region,
      credentials: fromCognitoIdentityPool({
        clientConfig: { region: config.id_region }, // Configure the underlying CognitoIdentityClient.
        identityPoolId: config.aws_id,
        logins: {
                // Optional tokens, used for authenticated login.
            },
      })
    })

    setS3Client(client)
  }, [])

  useEffect(() => {
    // Add FontAwesome to application
    const script = document.createElement("script");
    script.src = "https://kit.fontawesome.com/6026e7584a.js";
    script.crossorigin = "anonymous"
    document.body.appendChild(script);

    // Initialize flash messages
    window.flash = (message, type="success") => Bus.emit('flash', ({message, type}));
  }, [])



  return (
    <div style={{ overflowX: 'hidden' }}>
      <Flash />
      <nav className="navbar navbar-expand-lg p-1" style={{backgroundColor: "#273b63", width: "100%", zIndex: 100}}>
        <img src={CatImg} width="45" className="ms-2" />
        <span className="ms-2 text-white" style={{ fontSize: '24px' }}><b>PlasCAT</b>: Plasmid Cloud Assembly Tool</span>
        <div className="navbar-brand " style={{ marginLeft: "auto" }}>
          <span style={{ fontSize: '14px' }} className="me-1 text-white">powered by</span>
          <a target="_blank" href="https://www.genofab.com">
            <img src={Logo} width="180" />
          </a>
        </div>
      </nav>
      <div className="row" style={{backgroundColor: "#f7f8f9"}}>
        <div className="col mt-2">
          <Info />
        </div>
        <div className="col">
          <Instructions client={s3Client} />
        </div>
      </div>
      <Execute s3Client={s3Client} />
    </div>
  )
}


export default App
