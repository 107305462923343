import React , { useState, useEffect, useRef } from 'react';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd'

function debounce(func, wait) {
  let timeout;
  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(context, args);
    }, wait);
  };
}

const FileList = ({sortedFiles, onFileDragEnd, pipelineType, setDictFile, sortFromDict, removeSortedEntry, changeSampleName, changeLongReadSize, ...props}) => {

  const [heightOfRows, setHeightOfRows] = useState([])
  const inputRefs = useRef({});

  const focusInput = (index) => {
    if (inputRefs.current[index]) {
      inputRefs.current[index].focus();
    }
  };

  const getHeightOfRows = () => {
    if (pipelineType == 'hybrid') {
      const newHeights = sortedFiles.map((sorted, index) => {
        const element = document.getElementById(sorted.sample_name)
        if (element) {
          const height = element.offsetHeight
          return height
        }
        return ""
      })
      setHeightOfRows(newHeights)
    }
  }

  useEffect(() => {
    const handleResize = debounce(() => {
      // Handle window size changes here
      getHeightOfRows()
    }, 100); // Adjust the debounce delay as needed (e.g., 300 milliseconds)

    // Attach the debounced event listener when the component mounts
    window.addEventListener('resize', handleResize);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, [])

  useEffect(() => {
    getHeightOfRows()
  }, [sortedFiles])


  const long_read_list = (
    <ul className="list-group list-group-flush text-center">
      <li className="list-group-item">
        <p className="h5"><b>Long Reads</b></p>
      </li>
      {sortedFiles.map((sorted,index) => (
        <li className="list-group-item list-inline-item" key={index} style={ pipelineType == "hybrid" ? {height: heightOfRows[index]} : {}}>
          <div className="row">
            <div className="col">
              <b>({index+1})</b> {sorted.long_read ? sorted.long_read_key : "Missing"}
            </div>
            <div className="col">
              { sorted.long_read &&
                <select className="form-select" value={sorted.long_read_size} onChange={e=>changeLongReadSize(index, e.target.value)} style={{width: "130px"}}>
                  <option value="2000">2,000 bp</option>
                  <option value="5000">5,000 bp</option>
                  <option value="10000">10,000 bp</option>
                  <option value="15000">15,000 bp</option>
                </select>
              }
            </div>
            { pipelineType == "long" &&
              <div className="col">
                <button
                  type="button"
                  className="btn btn-outline-danger btn-sm ms-3"
                  onClick={()=>removeSortedEntry(index)}
                >
                  <i className="fas fa-trash-alt"></i>
                </button>
              </div>
            }
          </div>

        </li>
      ))}
    </ul>
  )


  const col_widths = ["20%", "35%", "35%", "10%"]

  const short_read_list = (
    <>
      <ul className={`list-group list-group-horizontal`}>
        <li className="list-group-item flex-fill" style={{width: col_widths[0]}}>
          <p className="h5"><b>Sample Names</b></p>
        </li>
        <li className="list-group-item flex-fill" style={{width: col_widths[1]}} >
          <p className="h5"><b>Short Forward Reads</b></p>
        </li>
        <li className="list-group-item flex-fill" style={{width: col_widths[2]}}>
          <p className="h5"><b>Short Reverse Reads</b></p>
        </li>
        <li className="list-group-item flex-fill" style={{width: col_widths[3]}}>
          <p className="h5"><b>Actions</b></p>
        </li>
      </ul>
      {sortedFiles.map((sorted,index) => (
        <ul className="list-group list-group-horizontal" id={sorted.sample_name} key={index}>
          <li className="list-group-item flex-fill" style={{width: col_widths[0]}}>
            {/*sorted.sample_name ? " "+sorted.sample_name : ""*/}
            <input className="form-control"
                    value={sorted.sample_name}
                    onChange={e=>changeSampleName(index, e.target.value)}
                    />
          </li>
          <li className="list-group-item flex-fill" style={{width: col_widths[1]}} >
            {sorted.short_forward_read ? sorted.short_forward_read.name.split(".")[0] : ""}
          </li>
          <li className="list-group-item flex-fill" style={{width: col_widths[2]}}>
            {sorted.short_reverse_read ? sorted.short_reverse_read.name.split(".")[0] : ""}
          </li>
          <li className="list-group-item flex-fill" style={{width: col_widths[3]}}>
            <button
              type="button"
              className="btn btn-outline-danger btn-sm"
              onClick={()=>removeSortedEntry(index)}
            >
              <i className="fas fa-trash-alt"></i>
            </button>
          </li>
        </ul>
      ))}
    </>
  )


  const short_read_list_dnd = (
    <>
    <ul className={`list-group list-group-horizontal`}>
      <li className="list-group-item flex-fill" style={{width: col_widths[0]}}>
        <p className="h5"><b>Sample Names</b></p>
      </li>
      <li className="list-group-item flex-fill" style={{width: col_widths[1]}} >
        <p className="h5"><b>Short Forward Reads</b></p>
      </li>
      <li className="list-group-item flex-fill" style={{width: col_widths[2]}}>
        <p className="h5"><b>Short Reverse Reads</b></p>
      </li>
      <li className="list-group-item flex-fill" style={{width: col_widths[3]}}>
        <p className="h5"><b>Actions</b></p>
      </li>
    </ul>
    <DragDropContext onDragEnd={onFileDragEnd}>
      <Droppable droppableId="droppable">
        {provided => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {sortedFiles.map((sorted,index) => (

              <Draggable draggableId={sorted.sample_name} index={index} key={sorted.sample_name}>
                {(provided, snapshot) => (
                  <ul
                    className={`list-group list-group-horizontal ${snapshot.isDragging ? "is-dragging" : ""}`}
                    id={sorted.sample_name}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <li className="list-group-item flex-fill" style={{width: col_widths[0]}}>
                      <div className="row">
                        <div className="col-1">
                          <i className="fas fa-bars"/>
                        </div>
                        <div className="col">
                        {/*sorted.sample_name ? " "+sorted.sample_name : ""*/}
                        <input className="form-control"
                                ref={(el) => inputRefs.current[index] = el}
                                value={sorted.sample_name}
                                onChange={e=>{
                                  const { selectionStart, selectionEnd } = e.target
                                  changeSampleName(index, e.target.value)
                                  setTimeout(() => {
                                    inputRefs.current[index].focus()
                                    inputRefs.current[index].setSelectionRange(selectionStart, selectionEnd)
                                  }, 0); // Delay focusing by 0 milliseconds
                                }}
                                />
                        </div>
                      </div>

                    </li>
                    <li className="list-group-item flex-fill" style={{width: col_widths[1]}} >
                      {sorted.short_forward_read ? sorted.short_forward_read.name.split(".")[0] : ""}
                    </li>
                    <li className="list-group-item flex-fill" style={{width: col_widths[2]}}>
                      {sorted.short_reverse_read ? sorted.short_reverse_read.name.split(".")[0] : ""}
                    </li>
                    <li className="list-group-item flex-fill" style={{width: col_widths[3]}}>
                      <button
                        type="button"
                        className="btn btn-outline-danger btn-sm"
                        onClick={()=>removeSortedEntry(index)}
                      >
                        <i className="fas fa-trash-alt"></i>
                      </button>
                    </li>
                  </ul>
                )}
              </Draggable>

            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
    </>
  )

  return (
    <div className="row ps-3 pe-3 pt-2">
      {pipelineType == "long" &&
        <div className="col-5">
          {long_read_list}
        </div>
      }
      { pipelineType == "short" &&
      <div className="col-11">
        {short_read_list}
      </div>
      }
      { pipelineType == "hybrid" &&
      <>
        {/*
        <div className="row mb-2">
          <div className="col-auto">
            <label htmlFor="fileUpload" accept={".txt"} className="form-label">Use Sorting Dictionary:</label>
          </div>
          <div className="col-auto">
            <input className="form-control mb-2" id="fileUpload" type="file" accept=".txt" onChange={(e)=>setDictFile(e.target.files[0])} />
          </div>
          <div className="col-auto">
            <button className="btn btn-primary" onClick={sortFromDict}>Filter from file</button>
          </div>
        </div>*/}
        <div className="col-3">
          {long_read_list}
        </div>
        <div className="col-9">
          {short_read_list_dnd}
        </div>
      </>
      }

    </div>
  )
}

export default FileList
