import React , { useState, useEffect, useRef } from 'react'
import FileList from "./FileList.jsx"
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

const PipelineForm = ({
        pipelineType,
        userEmail,
        setUserEmail,
        handlePipelineTypeChange,
        handleShortFilesInput,
        fileShortInputRef,
        fileLongInputRef,
        handleLongFilesInput,
        isLoading,
        uploadFiles,
        sortedFiles,
        uuid,
        clearData,
        totalNumUploaded,
        totalNumFiles,
        onFileDragEnd,
        setDictFile,
        sortFromDict,
        removeSortedEntry,
        changeSampleName,
        changeLongReadSize,
        ...props }) => {

    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
      navigator.clipboard.writeText(uuid)
        .then(() => setCopied(true))
        .catch((error) => console.error('Error copying text: ', error));
    };

  const infoTip = (infoText) => (
    <OverlayTrigger placement="top-start" overlay={<Tooltip id={infoText}>{infoText}</Tooltip>}>
        <i className="fas fa-info-circle text-muted ms-1"></i>
    </OverlayTrigger>
  )

  const copyTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {copied ? 'Copied!' : 'Copy'}
    </Tooltip>
  );

  return (
    <div className="row p-3">
      <h4>Run Assembly Pipeline</h4>
      <div className="row mb-2">
        <div className="col-4">
          <label htmlFor="email" className="form-label">Email (recieve notification from GenoFAB)</label>
          <input className="form-control" id="email" placeholder="Recommended" value={userEmail} onChange={e=>setUserEmail(e.target.value)}  />
        </div>
        <div className="col-2">
          <label htmlFor="pipelineType" className="form-label">Select Assembly Type</label>
          <select className="form-select" id="pipelineType" value={pipelineType} onChange={handlePipelineTypeChange}>
            <option value="short">Short reads</option>
            <option value="long">Long reads</option>
            <option value="hybrid">Hybrid reads</option>
          </select>
        </div>
        { (pipelineType == "short" || pipelineType == "hybrid") &&
          <div className="col-2">
            <label htmlFor="fileUploadShort" className="form-label">Attach Short Reads:{infoTip("Forward and reverse reads should have \"R1\" and \"R2\" in file name respectively. One sample (two reads) per folder. Nested folders allowed. File types kept are .fastq and .fastq.gz.")}</label>
            <input style={{"display": "none", "maxWidth": "10px"}} className="form-control mb-2" id="fileUploadShort" type="file" multiple directory="" webkitdirectory="" mozdirectory="" onChange={handleShortFilesInput} ref={fileShortInputRef} />
            <button className="form-control btn btn-primary" style={{"maxWidth": "160px"}} onClick={() => document.getElementById('fileUploadShort').click()}>
              Select Folder<i className="fas fa-folder-plus ms-2"></i>
            </button>
          </div>
        }
        { (pipelineType == "long" || pipelineType == "hybrid") &&
          <div className="col-2">
            <label htmlFor="fileUpload" className="form-label">Attach Long Reads:{infoTip("Each sample should be in a separate folder. Nested folders allowed. File types kept are .fastq and .fastq.gz.")}</label>
            <input style={{"display": "none"}} className="form-control mb-2" id="fileUploadLong" type="file" multiple  directory="" webkitdirectory="" mozdirectory="" onChange={handleLongFilesInput} ref={fileLongInputRef}/>
            <button className="form-control btn btn-primary" style={{"maxWidth": "160px"}} onClick={()=>document.getElementById('fileUploadLong').click()}>
              Select Folder<i className="fas fa-folder-plus ms-2"></i>
            </button>
          </div>
        }
      </div>
      {/*
      <div className="row mb-4">
      { (pipelineType == "short" || pipelineType == "hybrid") &&
        <div className="col-auto">
          <label htmlFor="fileUploadShort" className="form-label">Attach Short Reads:</label>
          <input style={{"display": "none"}} className="form-control mb-2" id="fileUploadShort" type="file" multiple directory="" webkitdirectory="" mozdirectory="" onChange={handleShortFilesInput} ref={fileShortInputRef} />
          <button className="btn btn-primary ms-2" onClick={() => document.getElementById('fileUploadShort').click()}>
            Select Folder<i className="fas fa-folder-plus ms-2"></i>
          </button>
        </div>
      }
      { (pipelineType == "long" || pipelineType == "hybrid") &&
        <div className="col">
          <label htmlFor="fileUpload" className="form-label">Attach Long Reads:</label>
          <input style={{"display": "none"}} className="form-control mb-2" id="fileUploadLong" type="file" multiple  directory="" webkitdirectory="" mozdirectory="" onChange={handleLongFilesInput} ref={fileLongInputRef}/>
          <button className="btn btn-primary ms-2" onClick={()=>document.getElementById('fileUploadLong').click()}>
            Select Folder<i className="fas fa-folder-plus ms-2"></i>
          </button>
        </div>
      }
      </div>
    */}
      { sortedFiles.length > 0 &&
        <FileList
          sortedFiles={sortedFiles}
          onFileDragEnd={onFileDragEnd}
          pipelineType={pipelineType}
          setDictFile={setDictFile}
          sortFromDict={sortFromDict}
          removeSortedEntry={removeSortedEntry}
          changeSampleName={changeSampleName}
          changeLongReadSize={changeLongReadSize}
        />
      }
      <div className="mt-2">
        <button className="btn btn-secondary me-2" onClick={clearData}>
          Clear
        </button>
        <button className="btn btn-primary" disabled={isLoading || sortedFiles.length == 0} onClick={() => uploadFiles(sortedFiles)}>
         { isLoading ? (
            <i className="fas fa-dna fa-spin"></i>
         ) : (
           <><i className="fas fa-cog me-2"></i>Execute</>
         )}
        </button>
        { uuid !== "" &&
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={copyTooltip}
          >
            <span className="ms-3" style={{ cursor: 'pointer' }} onClick={handleCopy}>
              Process Id: {uuid}<i className="far fa-clipboard ms-2" />
            </span>
          </OverlayTrigger>
        }
        { isLoading &&
          <>
            <div className="progress mt-2">
              <div className="progress-bar progress-bar-striped progress-bar-animated"
                  role="progressbar"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{"width": `${(totalNumUploaded/totalNumFiles)*100}%`}}
                  >
                  {totalNumUploaded} / {totalNumFiles} Files Uploaded
                </div>
            </div>
            <p className="">Uploading files. This may take a few minutes.</p>
          </>
        }
      </div>
    </div>
  )
}

export default PipelineForm
