import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

const AdvancedOptionsForm = ({ pipelineType, advancedOptions, setAdvancedOptions, ...props }) => {

    const label_class = "col-sm-5 col-form-label"

    const handleAdvOptChange = (typeSettings, option, value) => {
        let newOptions = {...advancedOptions}
        newOptions[typeSettings][option] = value
        setAdvancedOptions(newOptions)
    }

    const infoTip = (infoText) => (
        <OverlayTrigger placement="top-start" overlay={<Tooltip id={infoText}>{infoText}</Tooltip>}>
            <i className="fas fa-info-circle text-muted ms-1"></i>
        </OverlayTrigger>
    )

    return(
        <form className="row">
            { (pipelineType == "long" || pipelineType == "hybrid") &&
                <div className="col-4">
                    <h6>Long Read Assembly Options</h6>
                    <div className="row mb-2">
                        <label className={label_class} htmlFor="maxLength">Max Length:{infoTip("(Filtlong) Maximum read length (bp) [default: 20000]")}</label>
                        <div className="col-sm-6">
                            <input 
                                className="form-control" 
                                value={advancedOptions["long_reads"]["max_length"]} 
                                onChange={e=>handleAdvOptChange("long_reads", "max_length", e.target.value)} 
                                type="number" id="maxLength" step="100" min="0"
                            />
                        </div>
                    </div>
                    <div className="row mb-2">
                        <label className={label_class} htmlFor="keep">Keep Percent:{infoTip("(Filtlong) Percentage of good reads to keep [default: 80]")}</label>
                        <div className="col-sm-6">
                            <input 
                                className="form-control" type="number" id="keep" min="0" max="100"
                                value={advancedOptions["long_reads"]["keep_percent"]} 
                                onChange={e=>handleAdvOptChange("long_reads", "keep_percent", e.target.value)} 
                            />
                        </div>
                    </div>
                    {/*<div className="row mb-2">
                        <label className={label_class} htmlFor="size">Expected Size:</label>
                        <div className="col-sm-6">
                            <input 
                                className="form-control" type="number" id="size" step="1000" min="0"
                                value={advancedOptions["long_reads"]["expected_size"]} 
                                onChange={e=>handleAdvOptChange("long_reads", "expected_size", e.target.value)}
                            />
                        </div>
                    </div>*/}
                    <div className="row mb-2">
                        <label className={label_class} htmlFor="coverage">Coverage:{infoTip("(rasusa) Coverage to subsample long reads to [default: 1000]")}</label>
                        <div className="col-sm-6">
                            <input 
                                className="form-control" type="number" id="coverage" step="100" min="0"
                                value={advancedOptions["long_reads"]["coverage"]} 
                                onChange={e=>handleAdvOptChange("long_reads", "coverage", e.target.value)}    
                            />
                        </div>
                    </div>
                </div>
            }
            { (pipelineType == "short" || pipelineType == "hybrid") &&
                <div className="col-4">
                    <h6>Short Read Assembly Options</h6>
                    <div className="row mb-2">
                        <label className={label_class} htmlFor="minLength">Min Length:{infoTip("Minimum filtered short-read length [default: Trimmomatic=50, fastp=150]")}</label>
                        <div className="col-sm-6">
                            <input 
                                className="form-control" type="number" id="minLength" step="10" min="0"
                                value={advancedOptions["short_reads"]["min_length"]} 
                                onChange={e=>handleAdvOptChange("short_reads", "min_length", e.target.value)}    
                            />
                        </div>
                    </div>
                    <div className="row mb-2">
                        <label className={label_class} htmlFor="window">Filter Window:{infoTip("Filtering sliding window size [default: 9]")}</label>
                        <div className="col-sm-6">
                            <input 
                                className="form-control" type="number" id="window"  min="0"
                                value={advancedOptions["short_reads"]["filter_window"]} 
                                onChange={e=>handleAdvOptChange("short_reads", "filter_window", e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="row mb-2">
                        <label className={label_class} htmlFor="minQuality">Min Quality:{infoTip("Minimum filtering quality [default: Trimmomatic=35, fastp=38]")}</label>
                        <div className="col-sm-6">
                            <input 
                                className="form-control" type="number" id="minQuality" min="0"
                                value={advancedOptions["short_reads"]["min_quality"]} 
                                onChange={e=>handleAdvOptChange("short_reads", "min_quality", e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            }
        </form>
    )
}

export default AdvancedOptionsForm