const Info = () => {

    return (
      <div className="px-4  pb-1" >
        <h1 style={{"font-size": "calc(1.275rem + 0.3vw)"}}>De novo assembly of plasmid sequences</h1>
        <div>
          <div>
            <p>
                Paper: <a target="_blank" href={"https://doi.org/10.1093/bioinformatics/btae299"}>https://doi.org/10.1093/bioinformatics/btae299</a>
            </p>
            <p><i>Bioinformatics</i>, Volume 40, Issue 5, May 2024, btae299</p>
            <p>Authors: Samuel Peccoud, Casey-Tyler Berezin, Sarah I Hernandez, Jean Peccoud</p>
          </div>
        </div>
        <div>
          More info: <a className="me-3" target="_blank" href={"https://bitbucket.org/genofabinc/plascat-pipeline/src/main/"}><i className="fab fa-github"></i> Github</a>
          Need help? <a target="_blank" href={"https://support.genofab.com"}>Support</a>
        </div>
        <div>
         
        </div>
      </div>
    )
}

export default Info;
