import React , { useState, useEffect, useRef } from 'react';
import PipelineForm from "./PipelineForm.jsx"
import Retrieve from "./Retrieve.jsx"
import { PutObjectCommand } from "@aws-sdk/client-s3"
import axios from "axios"
import Collapse from 'react-bootstrap/Collapse'
import AdvancedOptionsForm from './AdvancedOptionsForm.jsx'


const reorderAttributes = (list, sourceIndex, destIndex) => {
  const result = [...list]; // Create a copy of the original list

  // Ensure sourceIndex and destIndex are within valid bounds
  if (sourceIndex < 0 || sourceIndex >= result.length || destIndex < 0 || destIndex >= result.length)
    return result // Invalid indices, return the original list

  // Extract the object at the sourceIndex
  const movedItem = result[sourceIndex]

  // Remove the original object from the list
  result.splice(sourceIndex, 1)

  // Determine the position to insert the moved item
  let insertIndex = destIndex

  // Reorder only the specified attributes
  const reorderedItem = {
    sample_name: movedItem.sample_name,
    short_forward_read: movedItem.short_forward_read,
    short_reverse_read: movedItem.short_reverse_read,
  };

  // Reinsert the reordered item at the correct position
  result.splice(insertIndex, 0, reorderedItem);

  list.map((obj, index) => {
    result[index] = {...result[index], long_read: obj.long_read, long_read_key: obj.long_read_key, long_read_size: obj.long_read_size}
  })

  return result;
}


const Execute = ({s3Client, ...props}) => {
    const [tab, setTab] = useState('execute')
    const [shortReadFiles, setShortReadFiles] = useState({})
    const [longReadFiles, setLongReadFiles] = useState({})
    const [dictFile, setDictFile] = useState({})
    const [sortedFiles, setSortedFiles] = useState([])
    const [pipelineType, setPipelineType] = useState('short')
    const [userEmail, setUserEmail] = useState("")
    const [uuid, setUuid] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [totalNumFiles, setTotalNumFiles] = useState(1)
    const [totalNumUploaded, setTotalNumUploaded] = useState(0)
    const [showAdvancedOptions, setShowAdvancedOptions] = useState(false)
    const [advancedOptions, setAdvancedOptions] = useState({"short_reads": {"min_length": 50, 
                                                                            "filter_window": 9,
                                                                            "min_quality": 35},
                                                            "long_reads": {"max_length": 20000, 
                                                                           "keep_percent": 80,
                                                                           //"expected_size": 2000,
                                                                           "coverage": 1000}})

    const fileShortInputRef = useRef(null);
    const fileLongInputRef = useRef(null);


    const fileReader = new FileReader()


    const clearData = () => {
      setSortedFiles([])
      setLongReadFiles([])
      setShortReadFiles([])
      setUuid("")
      clearFileInputs()
    }

    const clearFileInputs = () => {
      if (fileShortInputRef.current)
        fileShortInputRef.current.value = ''

      if (fileLongInputRef.current)
        fileLongInputRef.current.value = ''

      setShortReadFiles({})
      setLongReadFiles({})
    }


    const handleShortFilesInput = (e) => {
      handleFilesInput(e, setShortReadFiles, "short")
    }

    const handleLongFilesInput = (e) => {
      handleFilesInput(e, setLongReadFiles, "long")
    }

    const handleFilesInput = (e, setFiles, type) => {
      const fileDict = {}

      // Convert FileList Obj to Array
      const files = e.target.files;
      let filesArr = []
      for (let i = 0; i < files.length; i++) {
        filesArr[i] = files[i]
      }

      // Create Dictionary and impose file restrictions
      filesArr.map(file=>{
        // Make sure there are no .xxx files and all files are fastq.gz format
        if (!(/^\..+/.test(file.name)) && (/\.fastq\.gz$/.test(file.name))) {

          let filePath = file.webkitRelativePath.split('/')

          // Ensure short read file names contain direction key 'RX'
          if (type == "short" && /R1|R2/.test(file.name)) {
            let dictKey = filePath[filePath.length - 2].split("_")[0]
            if (fileDict[dictKey] == null )
              fileDict[dictKey] = [file]
            else if (fileDict[dictKey].length < 2)
              fileDict[dictKey] = [...fileDict[dictKey], file]
            else
              window.flash(`Too many files. Only two short reads accepted per folder. Extra files dropped.`, "error")
          }

          // Ensure long reads have folder name and 'barcode' in file
          if (type == "long") //&&
              // file.name.includes(filePath[filePath.length - 2]) &&
              // filePath[filePath.length - 2].includes("barcode"))
              {
            let dictKey = filePath[filePath.length - 2]
            fileDict[dictKey] = fileDict[dictKey] == null ? [file] : [...fileDict[dictKey], file]
          }

        }
      })

      if (Object.keys(fileDict).length == 0) {
        // console.log(fileDict);
        window.flash(`No files added. Check formatting`, "error")
      }


      setFiles(prev => {
        return {...prev, ...fileDict}
      })

    }

    const handlePipelineTypeChange = (e) => {
      setPipelineType(prev=> {
        const newType = e.target.value
        clearData()
        return newType
      })
    }

    useEffect(() => {
      if (Object.keys(longReadFiles).length > 0)
        sortNewLongFiles(longReadFiles)
    }, [longReadFiles])

    useEffect(() => {
      if (Object.keys(shortReadFiles).length > 0)
        sortNewShortFiles(shortReadFiles)
    }, [shortReadFiles])


    const sortNewLongFiles = (longReadFiles) => {
      let newSortedFiles = [...sortedFiles]
      let sortedLongReadKeys = Object.keys(longReadFiles).sort()

      // Add long reads to previous rows if missing long read data
      newSortedFiles.map((entry, index)=>{
        if (entry.long_read == null) {
          let key = sortedLongReadKeys.shift()
          let longFile = longReadFiles[key]
          newSortedFiles[index] = {...newSortedFiles[index], long_read_key: key, long_read: longFile, long_read_size: "2000"}
        }
      })

      // Add long read to end of list
      sortedLongReadKeys.map(key => {
        let longFile = longReadFiles[key]
        newSortedFiles.push({sample_name: key, long_read_key: key, long_read: longFile, long_read_size: "2000"})
      })

      setSortedFiles(newSortedFiles)
      clearFileInputs()
    }

    const sortNewShortFiles = (shortReadFiles) => {
      let newSortedFiles = [...sortedFiles]
      let sortedShortReadKeys = Object.keys(shortReadFiles).sort()

      // Add long reads to previous rows if missing long read data
      newSortedFiles.map((entry, index)=>{
        if (entry.short_forward_read == null || entry.short_reverse_read == null) {
          // console.log("Adding missing data at index:", index);
          let key = sortedShortReadKeys.shift()
          let shortFiles = shortReadFiles[key]
          let forwardRead = {}
          let reverseRead = {}

          // Find Forward and Reverse reads
          if (shortFiles.length == 2) {
            shortFiles.map(file => {
              if (file.name.includes("R1")) // R1 in file name means forward read
                forwardRead = file
              else
                reverseRead = file
            })
          }
          newSortedFiles[index] = {...newSortedFiles[index], sample_name: key, short_forward_read: forwardRead, short_reverse_read: reverseRead}
        }
      })

      // Add short reads to end of list
      sortedShortReadKeys.map((key, index) => {
        let shortFiles = shortReadFiles[key]
        let forwardRead = {}
        let reverseRead = {}

        // Find Forward and Reverse reads
        if (shortFiles.length == 2) {
          shortFiles.map(file => {
            if (file.name.includes("R1")) // R1 in file name means forward read
              forwardRead = file
            else
              reverseRead = file
          })
        }

        newSortedFiles.push({sample_name: key, short_forward_read: forwardRead, short_reverse_read: reverseRead})

      })


      setSortedFiles(newSortedFiles)
      clearFileInputs()
    }

    const sortFromDict = () => {

      if (dictFile?.name) {
        // console.log("sorting from file:", dictFile);

        fileReader.onload = function (event) {
            let text = String(event.target.result)
            text = text.replace(/'/g, '"')
            const dict = JSON.parse(text)

            let newSorted = []
            let numDropped = 0

            Object.keys(dict).map(dictKey => {

              if (sortedFiles.find(f => f.sample_name == dictKey)) {
                let sortedFi = sortedFiles.find(f => f.sample_name == dictKey)

                let mappedTo = dict[dictKey]
                let long_file = longReadFiles.find(lf => lf.name.includes(mappedTo))
                if (long_file != null) {
                  newSorted = [...newSorted, { ...sortedFi, long_read: long_file }]
                }

              } else {
                numDropped++
              }
            })

            if (newSorted.length == 0) {
              window.flash(`No matches to Dictionary`, "error")
            } else {
              setSortedFiles(newSorted)
              window.flash(`Sorted, ${numDropped} entries dropped`)
            }
        };

        fileReader.readAsText(dictFile)

      } else {
        window.flash("No Sorting File Found", "error")
      }
    }

    const createUploadPromise = (key, file) => {
      const params = {
        Bucket: 'plascat-bucket',
        Key: key,
        Body: file,
      };

      const command = new PutObjectCommand(params)
      return (
        s3Client.send(command).then(result=>{
          setTotalNumUploaded(prev=>prev+1)
        }).catch(err=>{
          // console.log("Error upload", err);
          window.flash("Storage error", "error")
          setIsLoading(false)
        })
      )
    }



    const uploadFiles = async (structFiles) => {
        setIsLoading(true)
        const requestId = generateUUID()
        setUuid(requestId)
        const uploadPath = `${requestId}/`

        // Check that all the data is there
        let stopFlag = false
        structFiles.map(obj=>{
          if (pipelineType == "long" || pipelineType == "hybrid") {
            if (obj.long_read == null) {
              stopFlag = true
            }
          }

          if (pipelineType == "short" || pipelineType == "hybrid") {
            if (obj.short_reverse_read == null || obj.short_forward_read == null) {
              stopFlag = true
            }
          }

        })

        if (stopFlag) {
          setIsLoading(false)
          window.flash("Missing Data, Please fill the table", "error")
          return
        }

        // Create an array of upload promises
        let uploadPromises = []

        structFiles.map(async(fileObj) => {

            if (pipelineType == "long") {

              fileObj.long_read.map(longFile => {
                const s3Key = uploadPath + fileObj.sample_name + "/long_read/" + longFile.name
                uploadPromises.push(createUploadPromise(s3Key, longFile))
              })


            } else if (pipelineType == "short") {

              let s3Key = uploadPath + fileObj.sample_name + "/short_read/" + fileObj.short_forward_read.name
              uploadPromises.push(createUploadPromise(s3Key, fileObj.short_forward_read))

              s3Key = uploadPath + fileObj.sample_name + "/short_read/" + fileObj.short_reverse_read.name
              uploadPromises.push(createUploadPromise(s3Key, fileObj.short_reverse_read))

            } else if (pipelineType == "hybrid") {
              let s3Key = uploadPath + fileObj.sample_name + "/short_read/" + fileObj.short_forward_read.name
              uploadPromises.push(createUploadPromise(s3Key, fileObj.short_forward_read))

              s3Key = uploadPath + fileObj.sample_name + "/short_read/" + fileObj.short_reverse_read.name
              uploadPromises.push(createUploadPromise(s3Key, fileObj.short_reverse_read))

              fileObj.long_read.map(longFile => {
                const s3Key = uploadPath + fileObj.sample_name + "/long_read/" + longFile.name
                uploadPromises.push(createUploadPromise(s3Key, longFile))
              })
            }


        });

        setTotalNumFiles(uploadPromises.length)

        // Use Promise.all to upload all files concurrently
        try {
            const results = await Promise.all(uploadPromises)
            window.flash("Files Successfully Uploaded")
            queryFlaskApp('plascat-bucket', uploadPath, requestId)
        } catch (err) {
            console.error(err)
            window.flash("File Upload Error", "error")
            setIsLoading(false)
        }

    }

    const queryFlaskApp = (s3Bucket, s3Key, requestId) => {
      const emailArg = isValidEmail(userEmail) ? `&email=${userEmail}` : ""
      const advOptArg = `&options=${JSON.stringify(advancedOptions)}`
      let longReadSizes = {}
      sortedFiles.map(sorted=>{
        longReadSizes[sorted.sample_name] = sorted.long_read_size
      })
      // console.log("longReadSizes: ", longReadSizes);
      const longReadSizeArg = (pipelineType == 'long' || pipelineType == 'hybrid') ? `&long_read_sizes=${JSON.stringify(longReadSizes)}` : ""

      axios.get(`https://api.genofab.com/execute?bucket=${s3Bucket}&key=${s3Key}&id=${requestId}&type=${pipelineType}${emailArg}${longReadSizeArg}${advOptArg}`)
      .then(response => {
        window.flash("Assembly Process Started")

        // Reset loading indicators
        setIsLoading(false)
        setTotalNumUploaded(0)
        setTotalNumFiles(1)
      })
      .catch(errors => {
        window.flash("Assembly process failed to start", "error")
        setIsLoading(false)
      })
    }


    const generateUUID = () => {
      // Generate a random array of 16 bytes (128 bits)
      var crypto = window.crypto || window.msCrypto; // Check for crypto object
      if (crypto && crypto.getRandomValues) {
          var buffer = new Uint8Array(16);
          crypto.getRandomValues(buffer);

          // Set the version (4) and variant (2) bits
          buffer[6] = (buffer[6] & 0x0f) | 0x40; // Version 4
          buffer[8] = (buffer[8] & 0x3f) | 0x80; // Variant 2

          // Convert the bytes to a hexadecimal string
          var uuid = Array.prototype.map.call(buffer, function(byte) {
              return ('0' + byte.toString(16)).slice(-2);
          }).join('');

          // Insert hyphens at the appropriate positions
          uuid = uuid.slice(0, 8) + '-' + uuid.slice(8, 12) + '-' + uuid.slice(12, 16) + '-' + uuid.slice(16, 20) + '-' + uuid.slice(20);

          return uuid;
      } else {
          // Fallback if crypto.getRandomValues is not available
          console.warn("crypto.getRandomValues is not available. Using less secure method.");
          return null; // Fallback to the previous method if crypto is not available
      }
    }

    const removeSortedEntry = (index) => {
      let newSortedFiles = [...sortedFiles]
      newSortedFiles.splice(index, 1)

      setSortedFiles(newSortedFiles)
    }


    const isValidEmail = (email) => {
      // Define a regular expression for a valid email pattern
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

      // Use the test() method to check if the email matches the pattern
      return emailPattern.test(email)
    }

    const onFileDragEnd = (result) => {
      // dropped outside the list
      if (!result.destination) {
        return
      }

      const reorderedEntries = reorderAttributes(
        sortedFiles,
        result.source.index,
        result.destination.index
      )
      // let entry = reorderedEntries[result.destination.index]
      // limsApi.patch(`entries/${entry.id}`, {...entry, position: result.destination.index}, "Entry Order Saved")

      setSortedFiles(reorderedEntries)
    }

    const changeSampleName = (index, newSampleName) => {
      let newSortedFiles = [...sortedFiles]
      newSortedFiles[index] = {...newSortedFiles[index], sample_name: newSampleName}

      setSortedFiles(newSortedFiles)
    }

    const changeLongReadSize = (index, newSize) => {
      let newSortedFiles = [...sortedFiles]
      newSortedFiles[index] = {...newSortedFiles[index], long_read_size: newSize}

      setSortedFiles(newSortedFiles)
    }


    return (
      <div className="px-4 pb-2">
        <div className="row">
          <div className="col p-1">
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <a className={`nav-link ${tab == 'execute' ? 'active' : ''}`} style={{ cursor: 'pointer' }} onClick={()=>setTab("execute")} >Execute pipeline</a>
              </li>
              <li className="nav-item">
                <a className={`nav-link ${tab == 'retrieve' ? 'active' : ''}`} style={{ cursor: 'pointer' }} onClick={()=>setTab("retrieve")} >Retrieve results</a>
              </li>
            </ul>
            {tab == "execute" ? (
              <>
                <PipelineForm
                  pipelineType={pipelineType}
                  userEmail={userEmail}
                  setUserEmail={setUserEmail}
                  handlePipelineTypeChange={handlePipelineTypeChange}
                  handleShortFilesInput={handleShortFilesInput}
                  fileShortInputRef={fileShortInputRef}
                  fileLongInputRef={fileLongInputRef}
                  handleLongFilesInput={handleLongFilesInput}
                  isLoading={isLoading}
                  uploadFiles={uploadFiles}
                  sortedFiles={sortedFiles}
                  uuid={uuid}
                  clearData={clearData}
                  totalNumUploaded={totalNumUploaded}
                  totalNumFiles={totalNumFiles}
                  onFileDragEnd={onFileDragEnd}//
                  setDictFile={setDictFile}
                  sortFromDict={sortFromDict}
                  removeSortedEntry={removeSortedEntry}
                  changeSampleName={changeSampleName}
                  changeLongReadSize={changeLongReadSize}
                />
                <div className='px-3 pb-3 pt-1'>
                  <button className='btn btn-light' onClick={()=>setShowAdvancedOptions(prev => !prev)}>
                    {showAdvancedOptions ? 
                      <i className="fas fa-angle-down me-1"></i> 
                      : 
                      <i className="fas fa-angle-right me-1"></i>
                    }
                    Advanced Options
                  </button>
                  <Collapse in={showAdvancedOptions}>
                    <div className="card card-body">
                      <AdvancedOptionsForm 
                        pipelineType={pipelineType} 
                        advancedOptions={advancedOptions} 
                        setAdvancedOptions={setAdvancedOptions} 
                      />
                    </div>
                  </Collapse>
                </div>
              </>
            ) : (
              <Retrieve s3Client={s3Client} />
            )}
          </div>
        </div>
      </div>
    )
}

export default Execute;
