import React , {useEffect, useState} from 'react';
import { GetObjectCommand, ListObjectsV2Command, } from "@aws-sdk/client-s3";

const Retrieve = ({s3Client, ...props}) => {

    const [id, setId] = useState("");
    const [countdown, setCountdown] = useState(10);
    const [isLoading, setIsLoading] = useState(false)
    const [isRefreshing, setIsRefreshing] = useState(false);


    const downloadBlob = (blob) => {
      const name = "results.zip"
      // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
      const blobUrl = URL.createObjectURL(blob);
      // Create a link element
      const link = document.createElement('a');
      // Set link's href to point to the Blob URL
      link.href = blobUrl;
      link.download = name;
      // Append link to the body
      document.body.appendChild(link);
      // Dispatch click event on the link
      // This is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );

      // Remove link from body
      document.body.removeChild(link);
    }


    const checkFolderPresence = async (requestId) => {
      const command = new ListObjectsV2Command({
        Bucket: "plascat-bucket",
        Prefix: requestId,
        MaxKeys: 1,
      });
    
      try {
        const { Contents } = await s3Client.send(command);
    
        if (Contents && Contents.length > 0) {
          console.log("The folder is not empty.");
          return true;
        } else {
          console.log("The folder is empty.");
          return false;
        }
      } catch (err) {
        console.log("Error checking folder presence:", err);
        return false;
      }
    };
    

    const downloadResults = async (requestId) => {
      setIsLoading(true)

      const command = new GetObjectCommand({
        Bucket: 'plascat-bucket',
        Key: requestId + "/results.zip"
      });

      const directoryPresent = await checkFolderPresence(requestId)

      s3Client.send(command).then(data=>{
        data.Body.transformToByteArray()
        .then(str=> {
          let zipBlob = new Blob([str], {type: 'application/zip;'});
          downloadBlob(zipBlob);
          setIsLoading(false)
          setIsRefreshing(false)
          window.flash("Results downloaded")
        }).catch(err=>{
          console.error(err)
          window.flash("Process corrupt", "error")
          setIsLoading(false)
          setIsRefreshing(false)
        })


      }).catch(err=>{
        // console.error(err)
        if (directoryPresent)
          window.flash("Assembly still in progress... ", "error")
        else
          window.flash("Process not found", "error")
        setIsLoading(false)
      })
    }

    

    useEffect(() => {
      let intervalId;


      console.log("Is loading", isLoading);
      if (isRefreshing && !isLoading) {
        intervalId = setInterval(() => {
          setCountdown((prevCountdown) => {
            if (prevCountdown == 1) {
              downloadResults(id)
            }
            if (prevCountdown === 0) {
              return 10
            }
            return prevCountdown - 1;
          });
        }, 1000); // Update countdown every second
      }

      // Clear interval when the component unmounts or when isRefreshing is set to false
      return () => clearInterval(intervalId);
    }, [isRefreshing, isLoading, id]);

    const toggleAutoRefresh = (event) => {
      let checked = event.target.checked

      if (checked) {
        downloadResults(id)
        setCountdown(10)
      }
        

      setIsRefreshing(checked)
    } 

    return (
      <div className="p-3">
        <h4>Download Results</h4>
        <div className="mb-3">
          <label htmlFor="processid" className="form-label">Enter Process Id</label>
          <input className="form-control mb-2" style={{maxWidth: "600px"}} id="processid" type="text" placeholder="XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX" value={id} onChange={(e)=>setId(e.target.value)}/>
          <div className='d-flex align-items-center'>
            <button className="btn btn-primary" disabled={isLoading || id.length == 0 || isRefreshing} onClick={() => downloadResults(id)}>
              {isLoading ? (
                <i className="fas fa-spinner fa-spin"></i>
              ) : (
                "Retrieve"
              )}
            </button>
            <div className="form-check form-switch ms-3">
              <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" onChange={(e)=>toggleAutoRefresh(e)} checked={isRefreshing} disabled={id.length == 0} />
              <label className="form-check-label" htmlFor="flexSwitchCheckDefault">{isRefreshing ? (countdown == 0 ? "Refreshing..." : `Refreshing in ${countdown} seconds`) : "Auto Refresh"}</label>
            </div>
          </div>
        </div>
      </div>
    )
}

export default Retrieve;
