import React, { useState } from "react";
import { S3Client, GetObjectCommand } from "@aws-sdk/client-s3";

const Instructions = ({ page, client, ...props }) => {

  const [loadingDemo, setLoadingDemo] = useState(false)


  const downloadDemo = () => {
    setLoadingDemo(true)
    const command = new GetObjectCommand({
      Bucket: 'plascat-bucket',
      Key: "demo/test-data.zip"
    });

    function downloadBlob(blob) {
      const name = "test-data.zip"
      // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
      const blobUrl = URL.createObjectURL(blob);
      // Create a link element
      const link = document.createElement('a');
      // Set link's href to point to the Blob URL
      link.href = blobUrl;
      link.download = name;
      // Append link to the body
      document.body.appendChild(link);
      // Dispatch click event on the link
      // This is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );

      // Remove link from body
      document.body.removeChild(link);
    }



    client.send(command).then(data=>{
      data.Body.transformToByteArray().then(str=> {
        let zipBlob = new Blob([str], {type: 'application/zip;'});
        downloadBlob(zipBlob);
        window.flash("Demo downloaded")
        setLoadingDemo(false)
      })
    }).catch(err=>{
      window.flash("Download error", "error")
      console.log(err);
      setLoadingDemo(false)
    })

  }

  return (
    <div className="card">
      {/* <div className="card-body">
        
      </div> */}
      <span className="list-group-item mt-2 ms-3"><h5 className="card-title">Instructions:</h5></span>
      <ol className="list-group list-group-flush list-group-numbered" style={{"listStylePosition":"outside"}}>
        <li className="list-group-item ms-2">Go to the execute pipeline tab.</li>
        <li className="list-group-item ms-2">Upload folder with sequencing data. (
          <a href="#" onClick={()=>downloadDemo()}>
            {loadingDemo ? <i className="fas fa-spinner fa-spin"></i> : "download demo data"}
          </a>)
        </li>
        <li className="list-group-item ms-2">Wait for results, keep note of your process id.</li>
        <li className="list-group-item ms-2">Results will be sent in an email.</li>
        <li className="list-group-item ms-2">Or, in retrieve results enter your process id to download.</li>
      </ol>
      {/*<div className="card-body">
        Need help? <a target="_blank" href={"https://support.genofab.com"}>Support</a>
      </div>*/}
    </div>
  )
}

export default Instructions
