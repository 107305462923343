import React, { useEffect, useState } from 'react';
import { Toast } from 'react-bootstrap'
import Bus from './bus'

const Flash = () => {
    let [show, setShow] = useState(false);
    let [message, setMessage] = useState('');
    let [type, setType] = useState('');

    useEffect(() => {
        if(document.querySelector('.close') !== null) {
            document.
            querySelector('.close').
            addEventListener('click', () => setShow(false));
        }
    })

    useEffect(() => {
        Bus.addListener('flash', ({message, type}) => {
            setShow(true);
            setMessage(message);
            setType(type);
        });
      }, []);

    // Changes the style of the header based on the type
    let iconStyle = {}
    if (type == 'success') {
      iconStyle = {color: '#14b527'}
    } else if (type == 'error') {
      iconStyle = {color: '#c71e45'}
    }
    return (
      <Toast
        onClose={() => setShow(false)} show={show} delay={(type == 'error') ? 6500 : 3000} autohide={false} className="w-auto"
        style={{
          top: '52px',
          left: '50%',
          position: 'fixed',
          transform: 'translate(-50%, 0px)',
          zIndex: 9999,
          minWidth: '200px'
        }}
        >
        <Toast.Header closeButton={false}>
          {type == 'success' ? (
            <i className="fas fa-check-circle me-2" style={iconStyle}></i>
          ) : (
            <i className="fas fa-exclamation-circle me-2" style={iconStyle}></i>
          )}
          <span
            className="me-2 text-dark"
            style={{position: "relative", top: "-1px"}}
          >
            {message}
          </span>
          <button type="button" className="btn-close ms-auto" aria-label="Close" onClick={() => setShow(false)}></button>
        </Toast.Header>
      </Toast>
    )
}

export default Flash
